import { useMemo } from "react";

import { useOptimizelyContext } from "./context";

export const useActiveExperiment = (experimentName: string) => {
  const { activeExperimentStates } = useOptimizelyContext();

  const experiment = useMemo(() => {
    if (!activeExperimentStates) return null;

    return Object.values(activeExperimentStates).find(
      state => state.experimentName === experimentName,
    );
  }, [experimentName, activeExperimentStates]);

  return { experiment };
};
