import { PaywallMeterModalClassName } from "./consts";

export const setPianoCustomVariable = (key: string, value: unknown) => {
  if (!window.tp) window.tp = [];
  setPianoVariable("setCustomVariable", key, value);
};

export const setPianoVariable = (eventName: string, ...parameters: unknown[]) => {
  if (!window.tp) window.tp = [];
  window.tp.push([eventName, ...parameters]);
};

export const closeAndClearUpPianoMeterModal = () => {
  if (!window.tp || !document.body.classList.contains(PaywallMeterModalClassName)) return;
  window.tp.offer?.close?.();
  document.body.classList.remove(PaywallMeterModalClassName);
};
