import { atom } from "jotai";
import type { ValuesType } from "utility-types";

import type { WallTypes } from "./consts";

export type LoginDialog = {
  application?: "posties" | "scmp-plus";
  description: string;
  destination: string;
  emailLoginTitle?: string;
  isVisible: boolean;
  registrationTerm?: string;
  title: string;
  variant: "dialog" | "dialog-with-primary-close-button-only" | "inline";
  wallType?: ValuesType<typeof WallTypes>;
};

export const loginDialogAtom = atom<LoginDialog>({
  description: "",
  destination: "",
  emailLoginTitle: "",
  isVisible: false,
  title: "",
  variant: "dialog",
});
