import rosettaSdk from "@product/rosetta-sdk";
import { notEmpty, PageType, useFallbackStorageValue } from "@product/scmp-sdk";
import { useAsync, useMountEffect } from "@react-hookz/web";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { checkIsContainsPlusQueryString } from "scmp-app/components/article/article-render/plus/helpers";
import { sendGTMSubscribeVariables } from "scmp-app/components/tracking/google-tag-manager/apis";
import { config } from "scmp-app/data";
import { getAccountState } from "scmp-app/lib/account";
import { useRosettaConfig } from "scmp-app/lib/app/hooks";
import { useParsedCookie } from "scmp-app/lib/hooks";
import { useRosettaEventHandlers } from "scmp-app/lib/rosetta";
import { usePaywallLtsOfferABTest } from "scmp-app/lib/rosetta/a-b-test/hooks";
import { setPianoCustomVariable } from "scmp-app/lib/rosetta/helpers";
import { ScmpSubscriptionsKey } from "scmp-app/lib/rosetta/hooks/consts";
import { useCurrentPageType, useRouteDetector } from "scmp-app/lib/router/hooks";
import { isExternalScriptsDisabled } from "scmp-app/lib/utils";

import { useRosettaSetState } from "./rosetta-set-state";

export const useRosettaSetup = () => {
  const initialized = useRef(false);
  const { handleUpdateRosettaState } = useRosettaSetState();
  const { pianoAccessToken, user } = getAccountState();
  const { update: updateScmpSubscriberCookie } = useParsedCookie("scmp_subscriber", raw => raw, {
    domain: config.account.cookieDomain,
    expires: 1,
  });
  // TODO: temporary add this for print article
  const { set: setScmpSubscriberStorage } = useFallbackStorageValue<string>("scmp_subscriber", {
    parse: value => value,
    stringify: value => value,
  });

  const { set: setScmpSubscriptionsStorage } = useFallbackStorageValue<string[]>(
    ScmpSubscriptionsKey,
    {
      defaultValue: [],
    },
  );

  const router = useRouter();
  const currentPageType = useCurrentPageType();
  const { getUrlAliasByUrl } = useRouteDetector();

  const { attachEventHandlers } = useRosettaEventHandlers();

  const [_, { execute }] = useAsync(async () => {
    if (initialized.current || isExternalScriptsDisabled("piano")) return;
    initialized.current = true;

    rosettaSdk
      .setConfig({
        contentService: {
          apiKey: config.rosetta.contentService.apiKey,
          apiUri: `${config.rosetta.apiGatewayHost}/content-delivery/v1`,
          env: parseEnvironment(config.rosetta.contentService.environment),
        },
        piano: {
          aid: config.rosetta.piano.applicationId,
          apiUri: config.rosetta.piano.apiUri,
          env: parsePianoEnvironment(config.rosetta.piano.environment),
        },
        rosetta: {
          env: parseEnvironment(config.rosetta.environment),
          uri: `${config.rosetta.subscribeHost}`,
        },
      })
      .setPageUrl(`${location.origin}${location.pathname}`)
      .setToken(pianoAccessToken);

    rosettaSdk.registerService("geo", {
      apiKey: config.rosetta.contentService.apiKey,
      apiUri: `${config.rosetta.apiGatewayHost}/gloc/v1`,
    });

    rosettaSdk.registerService("tierInfo", {
      apiKey: config.rosetta.contentService.apiKey,
      apiUri: `${config.corporateSubscriber.host}/tier`,
    });

    rosettaSdk.registerService("pianoUserInfo", {
      aid: config.rosetta.piano.applicationId,
      apiUri: `${config.rosetta.subscribeHost}/api/user`,
    });

    rosettaSdk.registerService("pianoPublisherConversions", {
      aid: config.rosetta.piano.applicationId,
      apiUri: `${config.rosetta.subscribeHost}/api/user/conversions`,
    });

    rosettaSdk.registerService("onboarding", {
      env: parseEnvironment(config.rosetta.contentService.environment),
      urlPrefix: config.rosetta.subscribeTemplateHost,
    });

    rosettaSdk.registerService("cartAbandoner", {
      cookieDomain: config.account.cookieDomain,
      env: parseEnvironment(config.rosetta.contentService.environment),
      urlPrefix: config.rosetta.subscribeTemplateHost,
      userTypeApiUri: `${config.general.userTypeHost}/client-id/`,
    });

    const handleGTMSubscribeVariables = (user?: rosettaSdk.RosettaUser) => {
      const { subscriptionProducts, tier } = user ?? {};
      notEmpty(tier) && sendGTMSubscribeVariables({ tier });
      notEmpty(subscriptionProducts?.[0]) &&
        sendGTMSubscribeVariables({ product: subscriptionProducts?.[0] });
    };

    const handlePianoCustomVariables = (tracking?: rosettaSdk.Tracking) => {
      setPianoCustomVariable("SUBSCRIBER_STATUS", tracking?.subscriberStatus);

      const username =
        user?.username?.split?.("@")?.[0] ?? `${user?.firstname ?? ""} ${user?.lastname ?? ""}`;
      notEmpty(username?.trim()) && setPianoCustomVariable("USER_NAME", username);

      const getSubscribeUrl = () => {
        const subscribeUrl = new URL(config.rosetta.subscribeHost);
        subscribeUrl.searchParams.set("destination", location.origin);
        return subscribeUrl.toString();
      };
      setPianoCustomVariable("subscribeUrl", getSubscribeUrl());
    };

    try {
      await rosettaSdk.init();
      attachEventHandlers();
      await rosettaSdk.initRules();
      const { supportPiano: isSubscriptionEnabled, supportVelocityWall: isShowVelocityWall } =
        rosettaSdk.rulesEngine.getOutcome("guest");

      // Article page will be executed after variable has been set with apps/app/components/article/article-render/hooks/piano-integration/index.ts
      const isScmpPlusArticlePage = checkIsContainsPlusQueryString(router.query);
      if (
        ![PageType.Article, PageType.PostiesArticle].includes(currentPageType) &&
        !isScmpPlusArticlePage
      ) {
        rosettaSdk.execute();
      }

      const hasToken = rosettaSdk.rulesEngine.getFact("hasToken");
      const rosettaUser = rosettaSdk.rulesEngine.getOutcome("user");
      const v2 = rosettaSdk.rulesEngine.getOutcome("v2");
      const tracking = rosettaSdk.rulesEngine.getOutcome("tracking");
      const isScmpSubscriber = rosettaSdk.rulesEngine.getOutcome("user.isSCMPSubscriber");
      const isPostiesSubscriber = rosettaSdk?.rulesEngine.getOutcome("user.isPostiesSubscriber");
      const hasPostiesAccessRight = rosettaSdk?.rulesEngine.getOutcome(
        "user.hasPostiesAccessRight",
      );
      const isScmpPlusSubscriber = rosettaSdk?.rulesEngine.getOutcome("user.isSCMPPlusSubscriber");
      const hasScmpPlusAccessRight = rosettaSdk?.rulesEngine.getOutcome(
        "user.hasSCMPPlusAccessRight",
      );

      handleGTMSubscribeVariables(rosettaUser);
      handlePianoCustomVariables(tracking);
      updateScmpSubscriberCookie(tracking.subscriberStatus);
      setScmpSubscriberStorage(tracking.subscriberStatus);
      setScmpSubscriptionsStorage([...(isScmpPlusSubscriber ? ["scmp-plus"] : [])]);

      handleUpdateRosettaState(
        {
          contentServiceUserInfo: {
            isShowVelocityWall,
            isSubscriptionEnabled,
          },
          hasPostiesAccessRight,
          hasScmpPlusAccessRight,
          instance: rosettaSdk,
          isPostiesSubscriber,
          isScmpPlusSubscriber,
          isScmpSubscriber,
          tracking,
          user: rosettaUser,
          v2,
        },
        "success",
      );

      // cart abandoner trigger
      const currentUrlAlias = getUrlAliasByUrl(window.location.href);
      const canShowCartAbandoner = ["/scmp_comments", "/posties", "/newsletters", "/yp"].every(
        x => !currentUrlAlias.startsWith(x),
      );
      if (canShowCartAbandoner) {
        rosettaSdk.getService("cartAbandoner")?.placeTrigger({
          hasToken,
          isRosetta: false,
          isSCMPSubscriber: isScmpSubscriber,
        });
      }
    } catch (error) {
      console.error("rosettaSdk init error", error);
      handleUpdateRosettaState(
        undefined,
        "error",
        error instanceof Error ? error : new Error(JSON.stringify(error)),
      );
    }
    try {
      rosettaSdk.initiateSwGLinkingFlow();
    } catch (error) {
      console.error("rosettaSdk initiateSwGLinkingFlow", error);
    }
  });
  useMountEffect(execute);

  const { rosettaConfigState } = useRosettaConfig();
  useEffect(() => {
    const {
      isShowPremiumPromo = false,
      oldArticleTimeframe = 15,
      paywallCampaign,
    } = rosettaConfigState.result?.paywallSettings ?? {};
    const { storage } = rosettaConfigState.result?.mixpanelSettings ?? {};
    handleUpdateRosettaState({
      appSettings: {
        isShowPremiumPromo,
        mixpanelStorage: storage,
        oldArticleTimeframe,
        paywallCampaignName: paywallCampaign?.name,
      },
      posties: {
        disablePaywall: rosettaConfigState.result?.posties?.disablePaywall,
      },
    });
  }, [handleUpdateRosettaState, rosettaConfigState]);

  usePaywallLtsOfferABTest();

  useEffect(() => {
    if (currentPageType !== PageType.Article) return;

    const handleOnReady = () => {
      const cleanup = rosettaSdk.setupPaywallCheckoutForPWA({
        account: {
          debugLog: config.account.debugLog,
          gtmContainerId: config.googleTagManager.containerId,
          isInWebView: false,
          mixpanelToken: config.account.mixPanelToken,
          recaptchaContainerId: config.account.recaptchaContainerId,
          recaptchaSiteKey: config.account.recaptchaSiteKey,
          url: config.account.scmpAccountHost,
        },
        scmp: {
          url: `https://${config.domain.scmp}`,
        },
      });

      return cleanup;
    };

    rosettaSdk.on("onReady", handleOnReady);

    return () => {
      rosettaSdk.off("onReady", handleOnReady);
    };
  }, [currentPageType]);

  function parseEnvironment(s: string) {
    switch (s.toLocaleLowerCase()) {
      case "production":
        return "production";
      default:
        return "development";
    }
  }

  function parsePianoEnvironment(s: string) {
    switch (s.toLocaleLowerCase()) {
      case "production":
        return "production";
      default:
        return "sandbox";
    }
  }
};

declare global {
  interface Window {
    tp?: Piano;
  }

  interface Piano extends Array<unknown> {
    customVariables?: {
      CHURN_EXPIRE_DATE?: number;
      CHURN_TERM_NAME?: string;
      currency?: string;
      geoLocation?: string;
      ip_access_client?: string;
      ip_access_user?: boolean;
      ip_ezproxy_user?: boolean;
      paywallCampaign?: string;
      subscribeLink?: string;
      SUBSCRIBER_STATUS?: string;
      trackingId?: string;
      USER_NAME?: string;
      zone?: string;
    };
    experience?: {
      execute: () => void;
    };
    offer?: {
      close: () => void;
    };
    user?: {
      isUserValid: () => boolean;
    };
  }
}
