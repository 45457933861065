import { AbTest, Lts } from "@product/rosetta-sdk";
import { useEffect } from "react";

import { useActiveExperiment } from "scmp-app/lib/optimizely";

export const usePaywallLtsOfferABTest = () => {
  const testName = Lts.LTS_AB_TEST_NAME;
  const { experiment } = useActiveExperiment(testName);

  useEffect(() => {
    if (!experiment) return;

    AbTest.setABTestVariablePianoCustomVariable({
      experimentId: experiment.id,
      testName,
      variant: experiment.variation,
    });
  }, [experiment, testName]);
};
