import type { AsyncStatus } from "@react-hookz/web";
import { useSetAtom } from "jotai";
import { useCallback } from "react";

import type { Rosetta } from "scmp-app/lib/rosetta/atoms";
import { rosettaAtom } from "scmp-app/lib/rosetta/atoms";

export const useRosettaSetState = () => {
  const setRosetta = useSetAtom(rosettaAtom);

  /**
   * This is for updating the global state of rosetta by merging the result instead of replacing the result
   */
  const handleUpdateRosettaState = useCallback(
    (rosettaResult?: Rosetta, status?: AsyncStatus, error?: Error) => {
      const handleUpdateStatus = (status: AsyncStatus, currentStatus?: AsyncStatus) => {
        const statusMap: Record<AsyncStatus, number> = {
          error: 3,
          loading: 1,
          "not-executed": 0,
          success: 2,
        };
        if (!currentStatus) return status;
        return statusMap[status] > statusMap[currentStatus] ? status : currentStatus;
      };
      setRosetta(state => ({
        error: error ?? state?.error,
        result: { ...state?.result, ...rosettaResult },
        status: handleUpdateStatus(status ?? "loading", state?.status),
      }));
    },
    [setRosetta],
  );

  return {
    handleUpdateRosettaState,
  };
};
