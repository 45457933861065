import { checkIsContainsPlusQueryString } from "scmp-app/components/tracking/google-analytics-4/apis";
import { removeNullOrUndefinedInObject } from "scmp-app/lib/utils";

import type { GA4Event, TrackingEvent, UntypedEvent, UntypedGA4Event } from "./types";

export function sendGTMSubscribeVariables(data: Record<string, unknown>) {
  window.dataLayer?.push({ subscribe: removeNullOrUndefinedInObject(data) });
}

export function setGTMCustomDimension(data: Record<string, unknown>) {
  window.dataLayer?.push({ page: { ...removeNullOrUndefinedInObject(data) } });
}

export function sendGA4GTMTracking<Untyped extends false | true = false>(
  event: Untyped extends true ? UntypedGA4Event : GA4Event,
  _options?: { untyped: Untyped },
) {
  window.dataLayer?.push(event);
}

export function sendGTMTracking<Untyped extends false | true = false>(
  event: Untyped extends true ? UntypedEvent : TrackingEvent,
  _options?: { untyped: Untyped },
) {
  window.dataLayer?.push(event);
}

export function trackPageView(data: Record<string, unknown>) {
  // Cspell:ignore Pageview
  sendGTMTracking({
    _clear: true,
    event: "VirtualPageview",
    page: {
      ...data,
      ...(checkIsContainsPlusQueryString() && {
        product: "plus",
      }),
      // if page_location is not provided, use the current window location
      ...(!data.page_location && {
        page_location: window.location.href,
      }),
    },
    subscribe: data.subscribe,
  });
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}
